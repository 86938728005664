.toolbar {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1px;
    background: #fff;
    padding: 4px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.toolbar button.toolbar-item {
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
}

.toolbar button.toolbar-item:disabled {
    cursor: not-allowed;
}

.toolbar button.toolbar-item.spaced {
    margin-right: 2px;
    gap: 10px
}

.toolbar button.toolbar-item.active {
    background-color: rgba(223, 232, 250, 0.3);
}

.toolbar button.toolbar-item.active i {
    opacity: 1;
}

.toolbar .toolbar-item:hover:not([disabled]) {
    background-color: #eee;
}

@media (max-width:1240px) {
    .divider {
        display: none;
    }
}

.divider:last-child {
    display: none;
}

.toolbar .divider {
    width: 1px;
    background-color: #eee;
    margin: 0 4px;
}

.toolbar .toolbar-item .text {
    display: flex;
    line-height: 20px;
    vertical-align: middle;
    font-size: 14px;
    color: #777;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 20px;
    text-align: left;
    gap: 10px;
}

.toolbar-icon {
    font-size: 16px;
    width: 16px;
}

.toolbar-icon-stacked {
    position: absolute;
    font-size: 16px;
    width: 16px;
    top: 0;
    left: 0;
}

.toolbar i.chevron-down {
    margin-top: 3px;
    width: 16px;
    height: 16px;
    display: flex;
    user-select: none;
}

.toolbar-icon-stack {
    position: relative;
    width: 16px;
    height: 16px;
}