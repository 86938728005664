.editor {
    flex: auto;
    position: relative;
    z-index: -1;
    max-height: 180px;
}

.editor-container {
    margin: 20px 20px 20px 20px;
    border-radius: 2px;
    color: #000;
    background: #fff;
    position: relative;
    display: block;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.editor-inner {
    background: #fff;
    position: relative;
    display: flex;
    position: relative;
    outline: 0;
    z-index: 0;
    overflow: auto;
    border: 0.5px solid #0000003b;
    border-radius: 4px;
}

.editor-input {
    font-size: 15px;
    display: block;
    position: relative;
    outline: 0;
    padding: 15px 10px;
    min-height: 100%;
    height: auto;
    white-space: break-spaces !important;
}

.editor-label {
    position: absolute;
    top: -9px;
    z-index: 1;
    left: 12px;
    background-color: white;
    padding: 0 5px;
    font-size: 0.75rem;
}

.editor-placeholder {
    color: #6d6d6d;
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    top: 15px;
    left: 15px;
    font-size: 13px;
    font-weight: 400;
    user-select: none;
    display: inline-block;
    pointer-events: none;
}

.editor-text-bold {
    font-weight: bold;
}

.editor-text-italic {
    font-style: italic;
}

.editor-text-underline {
    text-decoration: underline;
}

.editor-text-strikethrough {
    text-decoration: line-through;
}

.editor-text-underlineStrikethrough {
    text-decoration: underline line-through;
}

.editor-text-code {
    background-color: rgb(240, 242, 245);
    padding: 1px 0.25rem;
    font-family: Menlo, Consolas, Monaco, monospace;
    font-size: 94%;
}

.editor-link {
    color: rgb(33, 111, 219);
    text-decoration: none;
}

.editor-code {
    background-color: rgb(240, 242, 245);
    font-family: Menlo, Consolas, Monaco, monospace;
    display: block;
    padding: 8px 8px 8px 52px;
    line-height: 1.53;
    font-size: 13px;
    margin: 0;
    margin-top: 8px;
    margin-bottom: 8px;
    tab-size: 2;
    white-space: pre;
    overflow-x: auto;
    position: relative;
}

.editor-code:before {
    content: attr(data-gutter);
    position: absolute;
    background-color: #eee;
    left: 0;
    top: 0;
    border-right: 1px solid #ccc;
    padding: 8px;
    color: #777;
    white-space: pre-wrap;
    text-align: right;
    min-width: 25px;
}

.editor-code:after {
    content: attr(data-highlight-language);
    top: 0;
    right: 3px;
    padding: 3px;
    font-size: 10px;
    text-transform: uppercase;
    position: absolute;
    color: rgba(0, 0, 0, 0.5);
}

.editor-tokenComment {
    color: slategray;
}

.editor-tokenPunctuation {
    color: #999;
}

.editor-tokenProperty {
    color: #905;
}

.editor-tokenSelector {
    color: #690;
}

.editor-tokenOperator {
    color: #9a6e3a;
}

.editor-tokenAttr {
    color: #07a;
}

.editor-tokenVariable {
    color: #e90;
}

.editor-tokenFunction {
    color: #dd4a68;
}

.editor-paragraph {
    margin: 0;
    margin-bottom: 0.8rem;
}

.editor-paragraph:last-child {
    margin-bottom: 0;
}

.editor-quote {
    margin: 0;
    margin-left: 20px;
    font-size: 15px;
    color: rgb(101, 103, 107);
    border-left-color: rgb(206, 208, 212);
    border-left-width: 4px;
    border-left-style: solid;
    padding-left: 16px;
}

.editor-listitem-ol1 {
    padding: 0;
    margin: 0;
    list-style-position: inside;
}

.editor-listitem-ol2 {
    padding: 0;
    margin: 0;
    list-style-type: upper-alpha;
    list-style-position: inside;
}

.editor-listitem-ol3 {
    padding: 0;
    margin: 0;
    list-style-type: lower-alpha;
    list-style-position: inside;
}

.editor-listitem-ol4 {
    padding: 0;
    margin: 0;
    list-style-type: upper-roman;
    list-style-position: inside;
}

.editor-listitem-ol5 {
    padding: 0;
    margin: 0;
    list-style-type: lower-roman;
    list-style-position: inside;
}

.editor-listitem-ul {
    padding: 0;
    margin: 0;
    list-style-position: inside;
    margin-bottom: 0.8rem;
}

.editor-listitem-ol {
    padding: 0;
    margin: 0;
    list-style-position: inside;
    margin-bottom: 0.8rem;
}

.editor-listitem {
    margin: 0 32px;
}

.editor-listitem-checked,
.editor-listitem-unchecked {
    position: relative;
    margin-left: 8px;
    margin-right: 8px;
    padding-left: 24px;
    padding-right: 24px;
    list-style-type: none;
    outline: none;
}

.editor-listitem-checked {
    text-decoration: line-through;
}

.editor-listitem-unchecked:before,
.editor-listitem-checked:before {
    content: '';
    width: 16px;
    height: 16px;
    top: 2px;
    left: 0;
    cursor: pointer;
    display: block;
    background-size: cover;
    position: absolute;
}

.editor-listitem-unchecked[dir='rtl']:before,
.editor-listitem-checked[dir='rtl']:before {
    left: auto;
    right: 0;
}

.editor-listitem-unchecked:focus:before,
.editor-listitem-checked:focus:before {
    box-shadow: 0 0 0 2px #a6cdfe;
    border-radius: 2px;
}

.editor-listitem-unchecked:before {
    border: 1px solid #999;
    border-radius: 2px;
}

.editor-listitem-checked:before {
    border: 1px solid rgb(61, 135, 245);
    border-radius: 2px;
    background-color: #3d87f5;
    background-repeat: no-repeat;
}

.editor-listitem-checked:after {
    content: '';
    cursor: pointer;
    border-color: #fff;
    border-style: solid;
    position: absolute;
    display: block;
    top: 6px;
    width: 3px;
    left: 7px;
    right: 7px;
    height: 6px;
    transform: rotate(45deg);
    border-width: 0 2px 2px 0;
}

.editor-listitem-nested {
    list-style-type: none;
}

.editor-listitem-nested:before,
.editor-listitem-nested:after {
    display: none;
}

.emoji {
    color: transparent;
    background-size: 16px 16px;
    background-position: center;
    background-repeat: no-repeat;
    vertical-align: middle;
    margin: 0 -1px;
}

.emoji-inner {
    padding: 0 0.15em;
}

.emoji-inner::selection {
    color: transparent;
    background-color: rgba(150, 150, 150, 0.4);
}

.emoji-inner::moz-selection {
    color: transparent;
    background-color: rgba(150, 150, 150, 0.4);
}

.dropdown {
    z-index: 1300;
    display: block;
    position: absolute;
    box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
        inset 0 0 0 1px rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    min-width: 100px;
    min-height: 40px;
    background-color: #fff;
}

.dropdown .item {
    margin: 0 8px 0 8px;
    padding: 8px;
    color: #050505;
    cursor: pointer;
    line-height: 16px;
    font-size: 15px;
    display: flex;
    align-content: center;
    flex-direction: row;
    flex-shrink: 0;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 8px;
    border: 0;
    min-width: 268px;
    gap: 15px;
    align-items: center;
}

.dropdown .item .active {
    display: flex;
    width: 20px;
    height: 20px;
    background-size: contain;
}

.dropdown .item:first-child {
    margin-top: 8px;
}

.dropdown .item:last-child {
    margin-bottom: 8px;
}

.dropdown .item:hover {
    background-color: #eee;
}

.dropdown .item .text {
    display: flex;
    line-height: 20px;
    flex-grow: 1;
    width: 200px;
}

.dropdown .item .icon {
    display: flex;
    width: 20px;
    height: 20px;
    user-select: none;
    margin-right: 12px;
    line-height: 16px;
    background-size: contain;
}

.link-editor {
    position: absolute;
    z-index: 100;
    top: 0;
    left: 10;
    max-width: 300px;
    width: 100%;
    opacity: 0;
    background-color: #fff;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    transition: opacity 0.5s;
    will-change: transform;
}

.link-editor .link-actions {
    display: flex;
    gap: 10px;
}

.link-editor .link-actions button {
    border: none;
    border-radius: 3px;
    cursor: pointer;
}

.link-editor .link-input {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 24px);
    box-sizing: border-box;
    margin: 8px 12px;
    padding: 8px 12px;
    border-radius: 15px;
    background-color: #eee;
    font-size: 15px;
    color: rgb(5, 5, 5);
    border: 0;
    outline: 0;
    font-family: inherit;
}

.link-editor .link-input a {
    color: rgb(33, 111, 219);
    text-decoration: none;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 30px;
    text-overflow: ellipsis;
}

.link-editor .link-input a:hover {
    text-decoration: underline;
}

.tree-view-output {
    display: block;
    background: #222;
    color: #fff;
    padding: 5px;
    font-size: 12px;
    white-space: pre-wrap;
    margin: 1px auto 10px auto;
    max-height: 250px;
    position: relative;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: auto;
    line-height: 14px;
}

.debug-timetravel-panel {
    overflow: hidden;
    padding: 0 0 10px 0;
    margin: auto;
    display: flex;
}

.debug-timetravel-panel-slider {
    padding: 0;
    flex: 8;
}

.debug-timetravel-panel-button {
    padding: 0;
    border: 0;
    background: none;
    flex: 1;
    color: #fff;
    font-size: 12px;
}

.debug-timetravel-panel-button:hover {
    text-decoration: underline;
}

.debug-timetravel-button {
    border: 0;
    padding: 0;
    font-size: 12px;
    top: 10px;
    right: 15px;
    position: absolute;
    background: none;
    color: #fff;
}

.debug-timetravel-button:hover {
    text-decoration: underline;
}